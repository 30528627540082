define("ember-redirect/utils/array-swap", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (originalArray, x, y) {
    let tempArray = originalArray[x];
    originalArray[x] = originalArray[y];
    originalArray[y] = tempArray;
    return originalArray;
  };
});