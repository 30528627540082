define("liquid-wormhole/templates/components/liquid-destination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A943xffD",
    "block": "{\"symbols\":[\"stack\",\"item\"],\"statements\":[[4,\"each\",[[23,[\"stacks\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"liquid-destination-stack\"],[9],[0,\"\\n\"],[4,\"liquid-versions\",null,[[\"value\",\"notify\",\"renderWhenFalse\",\"name\",\"matchContext\",\"stackName\"],[[22,1,[\"lastObject\"]],[22,0,[]],true,\"liquid-wormhole\",[23,[\"matchContext\"]],[22,1,[\"name\"]]]],{\"statements\":[[0,\"      \"],[1,[27,\"liquid-append\",null,[[\"nodes\",\"replaceNodes\",\"notify\",\"click\"],[[22,2,[\"nodes\"]],[22,2,[\"_replaceNodes\"]],[22,2,[\"wormhole\"]],[22,2,[\"wormhole\",\"click\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-wormhole/templates/components/liquid-destination.hbs"
    }
  });

  _exports.default = _default;
});