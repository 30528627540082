define("ember-social-share/templates/components/gplus-share-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GeiEW3x6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"  \"],[7,\"svg\"],[11,\"style\",\"enable-background:new 0 0 512 512;\"],[11,\"version\",\"1.1\"],[11,\"viewBox\",\"0 0 512 512\"],[11,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n  \"],[7,\"g\"],[11,\"id\",\"XMLID_246_\"],[9],[0,\"\\n      \"],[7,\"g\"],[11,\"id\",\"XMLID_30_\"],[9],[0,\"\\n          \"],[7,\"path\"],[11,\"fill\",\"#DA4B42\"],[11,\"class\",\"st0\"],[11,\"d\",\"M181.6,306.9v51.3c0,0,49.7-0.1,70-0.1c-11,33.2-28,51.3-70,51.3c-42.5,0-75.6-34.4-75.6-76.9    c0-42.5,33.2-76.9,75.6-76.9c22.5,0,37,7.9,50.3,18.9c10.7-10.7,9.8-12.2,36.9-37.8c-23-20.9-53.6-33.7-87.1-33.7    c-71.5,0-129.5,58-129.5,129.5c0,71.5,58,129.5,129.5,129.5c106.9,0,133-93.1,124.3-155.1C280.7,306.9,181.6,306.9,181.6,306.9z     M415,309.4v-44.9h-32v44.9h-46.1v32h46.1v46.1h32v-46.1h44.9v-32H415z\"],[11,\"id\",\"XMLID_31_\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"span\"],[9],[14,1],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-social-share/templates/components/gplus-share-button.hbs"
    }
  });

  _exports.default = _default;
});