define('ember-markdown-html/helpers/render-markdown', ['exports', 'ember-markdown-html/markdown-html'], function (exports, _markdownHtml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.renderMarkdown = renderMarkdown;
  function renderMarkdown([path]) {
    const html = Ember.get(_markdownHtml.default, path.replace(/\//g, '.'));
    return Ember.String.htmlSafe(html);
  }

  exports.default = Ember.Helper.helper(renderMarkdown);
});