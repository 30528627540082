define("ember-redirect/utils/container", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.lookup = lookup;
	exports.register = register;
	function lookup(instance, ...args) {
		if (instance.lookup) {
			return instance.lookup(...args);
		}

		return instance.container.lookup.apply(instance.container, args);
	}

	function register(instance, ...args) {
		if (instance.register) {
			return instance.register(...args);
		}

		return instance.registry.register.apply(instance.registry, args);
	}
});