define('ember-carousel/components/carousel-arrow', ['exports', 'ember-carousel/templates/components/carousel-arrow'], function (exports, _carouselArrow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const carouselArrowClassMap = {
    left: 'carousel-left-arrow',
    right: 'carousel-right-arrow'
  };

  const carouselSlideActionMap = {
    left: 'slideLeft',
    right: 'slideRight'
  };

  exports.default = Ember.Component.extend({
    classNameBindings: ['carousel-arrow-class'],
    layout: _carouselArrow.default,

    'carousel-arrow-class': Ember.computed('direction', function () {
      return carouselArrowClassMap[Ember.get(this, 'direction')];
    }),

    click() {
      let method = carouselSlideActionMap[Ember.get(this, 'direction')];
      this.nearestWithProperty('isCarouselParentContainer')[method]();
    }
  });
});