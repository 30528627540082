define("ember-social-share/templates/components/fb-share-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UiDc0FQd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"viewBox\",\"0 0 16 16\"],[9],[0,\"\\n    \"],[7,\"path\"],[11,\"fill\",\"#ffffff\"],[11,\"fill-rule\",\"evenodd\"],[11,\"d\",\"M8 14H3.667C2.733 13.9 2 13.167 2 12.233V3.667A1.65 1.65 0 0 1\\n            3.667 2h8.666A1.65 1.65 0 0 1 14 3.667v8.566c0 .934-.733\\n            1.667-1.667\\n            1.767H10v-3.967h1.3l.7-2.066h-2V6.933c0-.466.167-.9.867-.9H12v-1.8c.033\\n            0-.933-.266-1.533-.266-1.267 0-2.434.7-2.467\\n            2.133v1.867H6v2.066h2V14z\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[9],[14,1],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-social-share/templates/components/fb-share-button.hbs"
    }
  });

  _exports.default = _default;
});