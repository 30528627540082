define('ember-markdown-it/helpers/markdown-render', ['exports', 'ember', 'markdown-it'], function (exports, _ember, _markdownIt) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.markdownRender = markdownRender;
  var EmString = _ember['default'].String;
  var isEmpty = _ember['default'].isEmpty;
  var htmlSafe = EmString.htmlSafe;

  function markdownRender(params) {
    if (isEmpty(params)) {
      return;
    }

    var _params = _slicedToArray(params, 1);

    var markdown = _params[0];

    var html = (0, _markdownIt['default'])().render(markdown);

    return htmlSafe(html);
  }

  exports['default'] = _ember['default'].Helper.helper(markdownRender);
});