define("ember-social-share/templates/components/twitter-share-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T3uzIw5a",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"i\"],[9],[10],[0,\"\\n\"],[7,\"span\"],[9],[14,1],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-social-share/templates/components/twitter-share-button.hbs"
    }
  });

  _exports.default = _default;
});