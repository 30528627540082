define('ember-responsive/services/media', ['exports', '@ember/string', 'ember-responsive/null-match-media'], function (exports, _string, _nullMatchMedia) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    _mocked: Ember.testing,
    _mockedBreakpoint: 'desktop',
    /**
    * A set of matching matchers.
    *
    * @property  matches
    * @type      Ember.NativeArray
    * @default   Ember.NativeArray
    */
    matches: Ember.computed(function () {
      return Ember.A(this.get('_mocked') ? [this.get('_mockedBreakpoint')] : []);
    }),

    /**
      * A hash of listeners indexed by their matcher's names
      *
      * @property
      * @type Object
      */
    listeners: {},

    /**
    * The matcher to use for testing media queries.
    *
    * @property  matcher
    * @type      matchMedia
    * @default   window.matchMedia
    * @private
    */
    mql: detectMatchMedia(),

    /**
     * Initialize the service based on the breakpoints config
     *
     * @method init
     *
     */
    init() {
      const owner = Ember.getOwner(this);
      const breakpoints = Ember.getOwner(this).lookup('breakpoints:main');
      if (breakpoints) {
        Object.keys(breakpoints).forEach(name => {
          let cpName = `is${(0, _string.classify)(name)}`;
          Ember.defineProperty(this, cpName, Ember.computed('matches.[]', function () {
            return this.get('matches').indexOf(name) > -1;
          }));
          this.match(name, breakpoints[name]);
        });
      }
    },

    /**
    * A string composed of all the matching matchers' names, turned into
    * friendly, dasherized class-names that are prefixed with `media-`.
    *
    * @property  classNames
    * @type      string
    */
    classNames: Ember.computed('matches.[]', function () {
      return this.get('matches').map(function (name) {
        return `media-${(0, _string.dasherize)(name)}`;
      }).join(' ');
    }),

    _triggerMediaChanged() {
      this.trigger('mediaChanged', {});
    },

    _triggerEvent() {
      Ember.run.once(this, this._triggerMediaChanged);
    },

    /**
    * Adds a new matcher to the list.
    *
    * After this method is called, you will be able to access the result
    * of the matcher as a property on this object.
    *
    * **Adding a new matcher**
    *
    * ```javascript
    * media = Ember.Responsive.Media.create();
    * media.match('all', 'all');
    * media.get('all');
    *   // => instanceof window.matchMedia
    * media.get('all.matches');
    *   // => true
    * ```
    *
    * @param   string  name   The name of the matcher
    * @param   string  query  The media query to match against
    * @method  match
    */
    match(name, query) {
      if (this.get('_mocked')) {
        return;
      }

      let matcher = this.get('mql')(query);

      let listener = matcher => {
        if (this.get('isDestroyed')) {
          return;
        }
        this.set(name, matcher);

        if (matcher.matches) {
          this.get('matches').addObject(name);
        } else {
          this.get('matches').removeObject(name);
        }
        this._triggerEvent();
      };
      this.get('listeners')[name] = listener;

      if (matcher.addListener) {
        matcher.addListener(function (matcher) {
          Ember.run(null, listener, matcher);
        });
      }
      listener(matcher);
    }
  });


  function detectMatchMedia() {
    if (typeof window === 'object' && window.matchMedia) {
      return window.matchMedia;
    }

    return _nullMatchMedia.default;
  }
});