define("ember-social-share/templates/components/share-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wNszhesk",
    "block": "{\"symbols\":[\"button\"],\"statements\":[[4,\"each\",[[23,[\"components\"]]],null,{\"statements\":[[0,\"  \"],[4,\"component\",[[22,1,[\"name\"]]],[[\"adaptive\",\"url\",\"image\",\"title\",\"text\",\"hashtags\",\"quote\",\"via\"],[[23,[\"adaptive\"]],[23,[\"url\"]],[23,[\"image\"]],[23,[\"title\"]],[23,[\"text\"]],[23,[\"hashtags\"]],[23,[\"quote\"]],[23,[\"via\"]]]],{\"statements\":[[1,[22,1,[\"label\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-social-share/templates/components/share-panel.hbs"
    }
  });

  _exports.default = _default;
});