define('ember-router-scroll/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    scheduler: Ember.inject.service('scheduler'),
    service: Ember.inject.service('router-scroll'),

    isFastBoot: Ember.computed(function () {
      const fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }),

    willTransition(...args) {
      this._super(...args);

      if (Ember.get(this, 'isFastBoot')) {
        return;
      }

      Ember.get(this, 'service').update();
    },

    didTransition(transitions, ...args) {
      this._super(transitions, ...args);

      if (Ember.get(this, 'isFastBoot')) {
        return;
      }

      const delayScrollTop = Ember.get(this, 'service.delayScrollTop');

      if (!delayScrollTop) {
        Ember.run.scheduleOnce('render', this, () => this.updateScrollPosition(transitions));
      } else {
        // as described in ember-app-scheduler, this addon can be used to delay rendering until after First Meaningful Paint.
        // If you loading your routes progressively, this may be a good option to delay scrollTop until the remaining DOM elements are painted.
        this.get('scheduler').scheduleWork('afterContentPaint', () => {
          this.updateScrollPosition(transitions);
        });
      }
    },

    updateScrollPosition(transitions) {
      const lastTransition = transitions[transitions.length - 1];

      let routerPath;
      if (typeof Ember.get(lastTransition, 'handler._router') !== 'undefined') {
        routerPath = 'handler._router';
      } else {
        routerPath = 'handler.router';
      }
      const url = Ember.get(lastTransition, `${routerPath}.currentURL`);
      const hashElement = url ? document.getElementById(url.split('#').pop()) : null;

      let scrollPosition;

      if (url && url.indexOf('#') > -1 && hashElement) {
        scrollPosition = { x: hashElement.offsetLeft, y: hashElement.offsetTop };
      } else {
        scrollPosition = Ember.get(this, 'service.position');
      }
      const preserveScrollPosition = Ember.get(lastTransition, 'handler.controller.preserveScrollPosition');

      if (!preserveScrollPosition) {
        const scrollElement = Ember.get(this, 'service.scrollElement');
        const targetElement = Ember.get(this, 'service.targetElement');

        if (targetElement) {
          window.scrollTo(scrollPosition.x, scrollPosition.y);
        } else if ('window' === scrollElement) {
          window.scrollTo(scrollPosition.x, scrollPosition.y);
        } else if ('#' === scrollElement.charAt(0)) {
          const element = document.getElementById(scrollElement.substring(1));

          if (element) {
            element.scrollLeft = scrollPosition.x;
            element.scrollTop = scrollPosition.y;
          }
        }
      }
    }
  });
});