define('ember-carousel/components/carousel-item', ['exports', 'ember-carousel/templates/components/carousel-item'], function (exports, _carouselItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [':carousel-item', 'isActive:active', 'slidingIn:slide-in', 'slidingOut:slide-out', 'from'],

    layout: _carouselItem.default,
    index: 0,

    didInsertElement() {
      this._super(...arguments);
      Ember.get(this, 'register')(this);

      let allItems = this.get('allItems');

      Ember.set(this, 'index', allItems.indexOf(this));
    },

    isActive: Ember.computed('allItems.@each', function () {
      return this === Ember.get(this, 'allItems.firstObject');
    })
  });
});