define("liquid-wormhole/templates/components/liquid-wormhole", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z03hR8bS",
    "block": "{\"symbols\":[\"details\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"hasSend\"]]],null,{\"statements\":[[4,\"if\",[[24,2]],null,{\"statements\":[[4,\"component\",[[23,[\"send\"]]],[[\"id\",\"class\"],[[23,[\"wormholeId\"]],[27,\"concat\",[[23,[\"wormholeClass\"]],\" liquid-wormhole-element\"],null]]],{\"statements\":[[0,\"      \"],[14,2,[[22,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"component\",[[23,[\"send\"]]],[[\"id\",\"class\"],[[23,[\"wormholeId\"]],[27,\"concat\",[[23,[\"wormholeClass\"]],\" liquid-wormhole-element\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[21,\"wormholeId\"]],[12,\"class\",[28,[[21,\"wormholeClass\"],\" liquid-wormhole-element\"]]],[9],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-wormhole/templates/components/liquid-wormhole.hbs"
    }
  });

  _exports.default = _default;
});