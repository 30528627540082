define("ember-background-video/templates/components/background-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rwiiBpRj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"video\"],[11,\"class\",\"jquery-background-video\"],[11,\"loop\",\"\"],[11,\"autoplay\",\"\"],[11,\"muted\",\"\"],[12,\"poster\",[21,\"poster\"]],[9],[0,\"\\n    \"],[4,\"if\",[[23,[\"mp4\"]]],null,{\"statements\":[[7,\"source\"],[12,\"src\",[28,[[21,\"mp4\"]]]],[11,\"type\",\"video/mp4\"],[9],[10]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"if\",[[23,[\"webm\"]]],null,{\"statements\":[[7,\"source\"],[12,\"src\",[28,[[21,\"webm\"]]]],[11,\"type\",\"video/webm\"],[9],[10]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"if\",[[23,[\"ogv\"]]],null,{\"statements\":[[7,\"source\"],[12,\"src\",[28,[[21,\"ogv\"]]]],[11,\"type\",\"video/ogg\"],[9],[10]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"ember-background-video--content\"],[9],[14,1],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-background-video/templates/components/background-video.hbs"
    }
  });

  _exports.default = _default;
});