define('ember-carousel/components/carousel-container', ['exports', 'ember-carousel/templates/components/carousel-container'], function (exports, _carouselContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['carousel-container'],

    layout: _carouselContainer.default,
    transitionInterval: 500,
    totalCarouselItems: Ember.computed.reads('carouselItems.length'),

    init() {
      this._super(...arguments);
      Ember.set(this, 'carouselItems', Ember.A());
    },

    activeCarouselItem: Ember.computed('carouselItems.{length,@each.isActive}', function () {
      return Ember.get(this, 'carouselItems').findBy('isActive');
    }),

    slide(newActiveIndex, direction) {
      let carouselItems = Ember.get(this, 'carouselItems');
      let activeCarouselItem = Ember.get(this, 'activeCarouselItem');
      let newActiveCarouselItem = carouselItems[newActiveIndex];
      let transitionInterval = Ember.get(this, 'transitionInterval');
      let transitionOffset = 50;

      Ember.run(function () {
        Ember.set(activeCarouselItem, 'from', direction);
        Ember.set(newActiveCarouselItem, 'from', direction);
      });

      Ember.run.later(function () {
        Ember.set(activeCarouselItem, 'slidingOut', true);
        Ember.set(newActiveCarouselItem, 'slidingIn', true);
      }, transitionOffset);

      Ember.run.later(function () {
        activeCarouselItem.setProperties({
          slidingOut: false,
          from: null,
          isActive: false
        });

        newActiveCarouselItem.setProperties({
          slidingIn: false,
          from: null,
          isActive: true
        });
      }, transitionInterval + transitionOffset);
    },

    actions: {
      slideRight() {
        let direction = 'right';
        let activeIndex = Ember.get(this, 'activeCarouselItem.index');
        let newActiveIndex = activeIndex - 1;

        if (activeIndex === 0) {
          newActiveIndex = Ember.get(this, 'totalCarouselItems') - 1;
        }

        if (Ember.get(this, 'onSlide')) {
          Ember.get(this, 'onSlide')({
            index: newActiveIndex,
            previousIndex: activeIndex,
            direction
          });
        }

        this.slide(newActiveIndex, direction);
      },

      slideLeft() {
        let direction = 'left';
        let activeIndex = Ember.get(this, 'activeCarouselItem.index');
        let newActiveIndex = activeIndex + 1;

        if (activeIndex === Ember.get(this, 'totalCarouselItems') - 1) {
          newActiveIndex = 0;
        }

        if (Ember.get(this, 'onSlide')) {
          Ember.get(this, 'onSlide')({
            index: newActiveIndex,
            previousIndex: activeIndex,
            direction
          });
        }

        this.slide(newActiveIndex, direction);
      },

      registerItem(item) {
        this.get('carouselItems').pushObject(item);
      }
    }
  });
});